import React from "react"
import {AppRoot, HomePage, Seo} from "components"

// override the default pageWrapperProps
const pageWrapperProps = {}

function Home({...props}) {
  return (
    <>
      <Seo />
      <AppRoot page={HomePage} pageWrapperProps={pageWrapperProps} {...props} />
    </>
  )
}

export default Home
